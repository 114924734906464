import './passenger-form.styles.scss';
import { Button } from '@mui/material';
import FormField from '../form-field/form-field.component';

const PassengerForm = ({id, passenger, onChange, onRemove, onBirthDateChange}) => {
    const { fullName, cpf, birthDate, email, phone, errors } = passenger;

    const handleRemovePassengerClick = () => {
        onRemove(passenger);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        const newPassenger = { ...passenger, [name]: value};
        onChange(id, newPassenger);
    };

    const handleBirthDateChange = () => {
        onBirthDateChange();
    }

    const getErrorMessage = (fieldName) => {
        return !!errors ? errors[fieldName] : null;
    }

    return (
        <div className='passenger-form-container'>
            <FormField 
                label='Nome completo' 
                name='fullName' 
                variant='light'
                required 
                onChange={handleChange} 
                value={fullName}
                errorMessage={getErrorMessage('fullName')}/>
            <FormField 
                label='CPF' 
                name='cpf'  
                variant='light'
                required 
                onChange={handleChange} 
                value={cpf}
                mask='999.999.999-99'
                errorMessage={getErrorMessage('cpf')}/>
            <FormField 
                label='Data de nascimento' 
                name='birthDate'  
                variant='light'
                required 
                onChange={handleChange} 
                onBlur={handleBirthDateChange}
                value={birthDate}
                mask='99/99/9999'
                errorMessage={getErrorMessage('birthDate')}/>
            <FormField 
                label='E-mail' 
                name='email'  
                variant='light'
                required 
                onChange={handleChange} 
                value={email}
                errorMessage={getErrorMessage('email')}/>
            <FormField 
                label='Telefone/Whatsapp' 
                name='phone'  
                variant='light'
                required 
                onChange={handleChange} 
                value={phone}
                mask='(99) 99999-9999'
                errorMessage={getErrorMessage('phone')}/>
            <Button className='button remove' variant='contained' onClick={handleRemovePassengerClick} color='error'>-</Button>
        </div>
    )
}

export default PassengerForm;