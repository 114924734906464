import './blog-post-preview.styles.scss';

const BlogPostPreview = ({post}) => {
    const { imageUrl, title, preview, postUrl } = post;
    return (
        <div className='blog-post-preview-container'>
            <img className='image' src={imageUrl}/>
            <div className='preview-container'>
                <h3 className='title'>{title}</h3>
                <span className='preview'>{preview}</span>
                <a className='action' href={postUrl} target='_blank'>
                    <span>LEIA MAIS &#8594;</span>
                </a>
            </div>
        </div>
    )
}

export default BlogPostPreview;