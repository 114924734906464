import './checkout.styles.scss';
import dayjs from 'dayjs';
import { Button } from '@mui/material';
import { useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CheckoutContext } from '../../context/checkout.context';
import { TripFiltersContext } from '../../context/trip-filters.context';
import { placeNewOrder } from '../../service/order.service';
import PassengerForm from '../../components/passenger-form/passenger-form.component';
import BasicInsuranceOffer from '../../components/basic-insurance-offer/basic-insurance-offer.component';
import BillingForm from '../../components/billing-form/billing-form.component';
import PaymentMethodSelector from '../../components/payment-method-selector/payment-method-selector.component';
import PurchaseDetails from '../../components/purchase-details/purchase-details.component';
import { Divider } from '@mui/material';

const Checkout = () => {
    const navigate = useNavigate();

    const { selectedInsurance, passengers, setPassengers, addPassenger, 
        removePassenger, billingInfo, setBillingInfo, paymentInfo, setPaymentInfo } = useContext(CheckoutContext);

    const { boardingDate, arrivalDate, destination, youngPassengers, oldPassengers, countPassengersByAge } = useContext(TripFiltersContext);

    const { passengerPrice, oldPassengerPrice } = selectedInsurance;

    const { method: paymentMethod } = paymentInfo;

    useEffect(() => {
        countPassengersByAge(passengers);
    }, [passengers]);

    const handleRemovePassengerClick = (passenger) => {
        removePassenger(passenger);
    }

    const handleAddPassengerClick = () => {
        addPassenger();
    }

    const handlePassengerChange = (idx, passenger) => {
        passengers.splice(idx, 1, passenger);
        setPassengers([...passengers]);
    }

    const handleCopyPassengerInfoClick = () => {
        setBillingInfo({ ...billingInfo, ...passengers[0] });
    }

    const handleBillingFormChange = (billingForm) => {
        setBillingInfo(billingForm);
    }

    const handlePaymentMethodSelection = (method) => {
        setPaymentInfo({...paymentInfo, method: method});
    }

    const countPassengers = () => {
        countPassengersByAge(passengers);
    }

    const isPassengersAgeValid = () => {
        let isValid = true;

        let newPassengers = [...passengers];

        newPassengers.map(passenger => {
            delete passenger.errors;
            let maxAgeBirthDate = dayjs().subtract(85, 'year');
            let passengerBirthDate = dayjs(passenger.birthDate, 'DD/MM/YYYY');

            if (passengerBirthDate.isBefore(maxAgeBirthDate)) {
                passenger.errors = { birthDate: 'Idade maior que 85 anos!' };
                isValid = false;
            }
        });

        setPassengers([...newPassengers]);

        return isValid;
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        if (isPassengersAgeValid()) {
            placeNewOrder({
                passengers,
                insurance: selectedInsurance,
                billingInfo, 
                paymentInfo,
                tripInfo: {
                    boardingDate, 
                    arrivalDate, 
                    destination
                }
            }).then(res => {
                if (res.status === 200 || res.status === 201) {
                    navigate('/order-feedback');
                }
            });
        } else {
            window.scrollTo(0, 0);
        }
    }

    return (
        <div className='checkout-container'>
            <div className='header'>
                <h3 className='title'>
                    Identificação dos Segurados
                </h3>
                <Link className='breadcrumb' to='/search-results'>
                    Voltar a pesquisa anterior &lt;
                </Link>
            </div>
                <form onSubmit={handleSubmit}>
            <div className='content'>
                <div className='column main-content'>
                    <div className='passengers-container'>
                        {passengers.map((passenger, idx) => (
                            <div key={idx}>
                                <PassengerForm id={idx} passenger={passenger} onChange={handlePassengerChange} onRemove={handleRemovePassengerClick} onBirthDateChange={countPassengers}/>
                                <Divider className='divider'/>
                            </div>
                        ))}
                        <Button className='button add-passenger' variant='contained' onClick={handleAddPassengerClick}>
                            <div className='plus-button'><span>+</span></div>
                            <h3>Adicionar mais passageiros</h3>
                        </Button>
                    </div>
                    <div className='billing-info-container'>
                        <h3 className='title'>
                            Dados para receber os vouchers
                        </h3>
                        <Button className='link' variant='outlined' size='small' onClick={handleCopyPassengerInfoClick}>
                            utilizar dados do 1° segurado
                        </Button>
                        <BillingForm billingInfo={billingInfo} onChange={handleBillingFormChange}/>
                    </div>
                    <div className='payment-info-container'>
                        <h3 className='title'>
                            Forma de pagamento
                        </h3>
                        <PaymentMethodSelector selected={paymentMethod} onSelect={handlePaymentMethodSelection}/>
                    </div>
                </div>
                <div className='column side-content'>
                    <BasicInsuranceOffer insurance={selectedInsurance} destination={destination} boardingDate={boardingDate} arrivalDate={arrivalDate}/>
                    <PurchaseDetails purchase={{ youngPassengers, oldPassengers, passengerPrice, oldPassengerPrice, paymentMethod }}/>
                    <Button type='submit' className='button place-order' variant='contained'><h3>Finalizar pedido</h3></Button>
                    <span className='terms-of-use'>
                        Ao clicar em Finalizar pedido, concordo que li e aceito os 
                        <a className="terms-link" href="https://drive.google.com/file/d/1U3CE3VDg1-7lMKkdTSVyjQepqD23hYOK/view" target="_blank">
                            &nbsp;Termos de Uso, Condições Gerais e Coberturas
                        </a>
                    </span>
                </div>
            </div>
                </form>
        </div>
    )
}

export default Checkout;