import './insurance-purchase-step.styles.scss';

const InsurancePurchaseStep = ({ className, logoUrl, title, text }) => {
    return (
        <div className={`insurance-purchase-step-container ${className}`}>
            <div className='logo-container'>
                <img src={logoUrl}/>
            </div>
            <h3 className='title'>{title}</h3>
            <span className='text'>{text}</span>
        </div>
    )
}

export default InsurancePurchaseStep;