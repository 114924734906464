import './advantage-box.styles.scss';

const AdvantageBox = ({advantage}) => {
    const { imageUrl, title, text } = advantage;
    return (
        <div className='advantage-box-container'>
            <img className='image' src={imageUrl}/>
            <span className='title'>{title}</span>
            <span className='text'>{text}</span>
        </div>
    )
}

export default AdvantageBox;