import './footer.styles.scss';
import Logo from '../../assets/logos/dark/horizontal1.svg';
import FacebookIcon from '../../assets/icons/facebook.svg';
import InstagramIcon from '../../assets/icons/instagram.svg';
import TiktokIcon from '../../assets/icons/tiktok.svg';
import TwitterIcon from '../../assets/icons/twitter.svg';
import WhatsappIcon from '../../assets/icons/whatsapp.svg';
import YoutubeIcon from '../../assets/icons/youtube.svg';
import ItauLogo from '../../assets/icons/itau.svg';
import BancoDoBrasilLogo from '../../assets/icons/banco-do-brasil.svg';
import BanrisulLogo from '../../assets/icons/banrisul.svg';
import BoletoLogo from '../../assets/icons/boleto.png';
import BradescoLogo from '../../assets/icons/bradesco.png';
import EloLogo from '../../assets/icons/elo.svg';
import HiperLogo from '../../assets/icons/hiper.svg';
import MasterCardLogo from '../../assets/icons/mastercard.svg';
import PixLogo from '../../assets/icons/pix.png';
import VisaLogo from '../../assets/icons/visa.svg';
import { Link } from 'react-router-dom';
import { Divider } from '@mui/material';

const Footer = () => {
    return (
        <div className='footer-background'>
            <div className='footer-container'>
                <img className='logo' src={Logo}/>
                <div className='content-container'>
                    <div className='content'>
                        <span className='title'>Quem somos</span>
                        <a className='option' href='https://api.whatsapp.com/send?phone=5543996823513' target='_blank'>
                            <span>Atendimento</span>
                        </a>
                        <a className='option' href='' target='_blank'>
                            <span>Política de privacidade</span>
                        </a>
                        <a className='option' href='' target='_blank'>
                            <span>Termos de uso</span>
                        </a>
                        <a className='option' href='https://assegurados.com/sobre-nos/' target='_blank'>
                            <span>Sobre nós</span>
                        </a>
                    </div>
                    <div className='content'>
                        <span className='title'>Seguradoras</span>
                        <a className='option' href='https://assegurados.com/assist-card/' target='_blank'>
                            <span>Assist Card</span>
                        </a>
                        <a className='option' href='https://assegurados.com/universal-assitance/' target='_blank'>
                            <span>Universal Assistance</span>
                        </a>
                        <a className='option' href='https://assegurados.com/affinity/' target='_blank'>
                            <span>Affinity</span>
                        </a>
                        {/* <a className='option' href='' target='_blank'>
                            <span>Coris</span>
                        </a> */}
                    </div>
                    <div className='content'>
                        <span className='title'>Blog</span>
                        <a className='option' href='https://assegurados.com/seguro-viagem-descubra-por-que-e-essencial-para-sua-viagem/' target='_blank'>
                            <span>Seguro viagem: descubra por que é essencial para a sua viagem</span>
                        </a>
                        <a className='option' href='https://assegurados.com/vai-viajar-para-fora-confira-nosso-checklist/' target='_blank'>
                            <span>Vai viajar para fora? Confira nosso checklist</span>
                        </a>
                        <a className='option' href='https://assegurados.com/orlando-o-melhor-guia-pelos-parques-tematicos/' target='_blank'>
                            <span>Orlando: o melhor guia pelos parques temáticos</span>
                        </a>
                    </div>
                    <div className='content'>
                        <span className='title'>Atendimento</span>
                        <a className='option' href='https://api.whatsapp.com/send?phone=5543996823513' target='_blank'>
                            <span><span style={{fontWeight: 'bold'}}>Whatsapp: </span>(43) 99682.3513</span>
                        </a>
                        <span className='option'><span style={{fontWeight: 'bold'}}>Email: </span>adm@assegurados.com</span>
                    </div>
                </div>
                <div className='content-container'>
                    <div className='content-container'>
                        <div className='content'>
                            <span className='title'>Nos acompanhe nas redes sociais</span>
                            <div className='icons-container'>
                                <a href='https://www.facebook.com/profile.php?id=100083402373891' target='_blank'>
                                    <img className='icon' src={FacebookIcon}/>
                                </a>
                                <a href='https://www.instagram.com/assegurados.oficial/' target='_blank'>
                                    <img className='icon' src={InstagramIcon}/>
                                </a>
                                {/* <a href='' target='_blank'>
                                    <img className='icon' src={TwitterIcon}/>
                                </a>
                                <a href='' target='_blank'>
                                    <img className='icon' src={YoutubeIcon}/>
                                </a>
                                <a href='' target='_blank'>
                                    <img className='icon' src={TiktokIcon}/>
                                </a> */}
                                <a href='https://api.whatsapp.com/send?phone=5543996823513' target='_blank'>
                                    <img className='icon' src={WhatsappIcon}/>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className='content-container'>
                        <div className='payment-method-container' style={{width: '120px'}}>
                            <span className='title'>Nossas formas de pagamentos</span>
                        </div>
                        <div className='payment-method-container'>
                            <span>Crédito em até 12x</span>
                            <div className='icons-container'>
                                <img className='icon' src={VisaLogo}/>
                                <img className='icon' src={MasterCardLogo}/>
                                <img className='icon' src={HiperLogo}/>
                                <img className='icon' src={EloLogo}/>
                            </div>
                        </div>
                        <div className='payment-method-container'>
                            <span>Transferencia báncaria</span>
                            <div className='icons-container'>
                                <img className='icon' src={ItauLogo}/>
                                <img className='icon' src={BradescoLogo}/>
                                <img className='icon' src={BancoDoBrasilLogo}/>
                                <img className='icon' src={BanrisulLogo}/>
                            </div>
                        </div>
                        <div className='payment-method-container'>
                            <span>Boleto bancário e pix</span>
                            <div className='icons-container'>
                                <img className='icon' src={BoletoLogo}/>
                                <img className='icon' src={PixLogo}/>
                            </div>
                        </div>
                    </div>
                </div>
                <Divider className='divider'/>
                <span className='copyrights'>Assegurados © 2022 – Todos os Direitos reservados</span>
            </div>
        </div>
    )
}

export default Footer;