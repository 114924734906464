import './billing-form.styles.scss';
import FormField from '../form-field/form-field.component';
import SelectField from '../select-field/select-field.component';

const stateList = [ 
    'AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'ES', 'GO', 'MA', 
    'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 
    'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO', 'DF'
]

const BillingForm = ({billingInfo, onChange}) => {
    const { fullName, cpf, email, phone, cep, address, addressNumber, neighborhood, otherInfo, city, state } = billingInfo;

    const handleChange = (event) => {
        const { name, value } = event.target;
        onChange({ ...billingInfo, [name]: value});
    };

    return (
        <div className='billing-form-container'>
            <FormField 
                type='text'
                label='Nome completo' 
                name='fullName' 
                variant='light'
                required 
                onChange={handleChange} 
                value={fullName}/>
            <FormField 
                type='text'
                label='CPF' 
                name='cpf'  
                variant='light'
                required 
                onChange={handleChange} 
                value={cpf}
                mask='999.999.999-99'/>
            <FormField 
                type='email'
                label='E-mail' 
                name='email'  
                variant='light'
                required 
                onChange={handleChange} 
                value={email}/>
            <FormField 
                type='text'
                label='Telefone' 
                name='phone'  
                variant='light'
                required 
                onChange={handleChange} 
                value={phone}
                mask='(99) 99999-9999'/>
            <FormField 
                type='text'
                label='CEP' 
                name='cep' 
                variant='light'
                required 
                onChange={handleChange} 
                value={cep}
                mask='99999-999'/>
            <FormField 
                type='text'
                label='Endereço' 
                name='address' 
                variant='light'
                required 
                onChange={handleChange} 
                value={address}/>
            <FormField 
                type='number'
                label='Número' 
                name='addressNumber' 
                variant='light'
                required 
                onChange={handleChange} 
                value={addressNumber}/>
            <FormField 
                type='text'
                label='Complemento (opcional)' 
                name='otherInfo' 
                variant='light'
                onChange={handleChange} 
                value={otherInfo}/>
            <FormField 
                type='text'
                label='Bairro' 
                name='neighborhood' 
                variant='light'
                required 
                onChange={handleChange} 
                value={neighborhood}/>
            <FormField 
                type='text'
                label='Cidade' 
                name='city' 
                variant='light'
                required 
                onChange={handleChange} 
                value={city}/>
            <SelectField 
                type='text'
                label='Selecione um estado' 
                name='state' 
                variant='light'
                autocomplete='off'
                required 
                onChange={handleChange} 
                value={state}
                options={stateList}/>
        </div>
    );
}

export default BillingForm;