import './trip-ensurance.styles.scss';
import WorldMapBackground from '../../assets/background-images/mapa.svg';
import WelcomeMessage from '../../components/welcome-message/welcome-message.component';
import TripSearcher from '../../components/trip-searcher/trip-searcher.component';
import PartnersShowcase from '../../components/partners-showcase/partners-showcase.component';

const TripEnsurance = () => {
    return (
        <div className='trip-ensurance-container' style={{backgroundImage: `url(${WorldMapBackground})`}}>
            <WelcomeMessage/>
            <TripSearcher/>
            <PartnersShowcase/>
        </div>
    )
}

export default TripEnsurance;