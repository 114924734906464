import { createContext, useState } from "react";

const defaultPassenger = {
    fullName: '', 
    cpf: '', 
    birthDate: '', 
    email: '', 
    phone: ''
}

const defaultBillingInfo = {
    fullName: '', 
    cpf: '', 
    email: '', 
    phone: '', 
    cep: '', 
    address: '', 
    addressNumber: '', 
    neighborhood: '', 
    otherInfo: '', 
    city: '', 
    state: ''
}

const defaultPaymentInfo = {
    method: 'credit-card'
}

export const CheckoutContext = createContext({
    selectedInsurance: {},
    setSelectedInsurance: () => {},
    passengers: [],
    setPassengers: () => {},
    billingInfo: {},
    setBillingInfo: () => {},
    paymentInfo: {},
    setPaymentInfo: () => {},
});

export const CheckoutProvider = ({children}) => {
    const [selectedInsurance, setSelectedInsurance] = useState({});
    const [passengers, setPassengers] = useState([defaultPassenger]);
    const [billingInfo, setBillingInfo] = useState(defaultBillingInfo);
    const [paymentInfo, setPaymentInfo] = useState(defaultPaymentInfo);
    
    const addPassenger = () => {
        setPassengers([...passengers, defaultPassenger]);
    }

    const removePassenger = (passenger) => {
        if (passengers.length > 1) {
            let passengerIdx = passengers.indexOf(passenger);
            passengers.splice(passengerIdx, 1);

            setPassengers([...passengers]);
        }
    }

    const value = {
        selectedInsurance,
        setSelectedInsurance, 
        passengers,
        setPassengers,
        addPassenger,
        removePassenger,
        billingInfo,
        setBillingInfo,
        paymentInfo,
        setPaymentInfo,
    };

    return <CheckoutContext.Provider value={value}>{children}</CheckoutContext.Provider>;
}