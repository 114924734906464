import './calendar-picker.styles.scss';
import dayjs from 'dayjs';
import { useState } from 'react';
import { parseToPtBrMonth } from '../../service/date.service';

const CalendarPicker = ({handleDateClick, selectedDate, minDate = dayjs()}) => {
    let [date, setDate] = useState(selectedDate ? selectedDate : (minDate ? minDate : dayjs()));
    dayjs.locale('pt-br');

    const renderCalendar = () => {
        const monthStart = date.startOf('month');
        const monthEnd = date.endOf('month');
        const startDate = monthStart.startOf('week');
        const endDate = monthEnd.endOf('week');
        const days = [];
        let day = startDate;
    
        while (day.isBefore(endDate)) {
            days.push(day);
            day = day.add(1, 'day');
        }
  
        return days.map((day) => {
            const canSelectDate = day.isAfter(minDate, 'day') || day.isSame(minDate, 'day');
            const isSameDate = day.isSame(date, 'day');

            const onClick = () => {
                if (canSelectDate) {
                    handleDateClick(day, true);
                }
            }
            
            return (
                <div
                    key={day.toISOString()}
                    className={`day ${isSameDate ? 'selected' : ''} ${canSelectDate ? 'valid' : 'invalid'}`}
                    onClick={onClick}
                    >
                    {day.format('D')}
                </div>
            );
        });
    };
  
    const handlePreviousMonth = () => {
        setDate(date.subtract(1, 'month'), false);
    }
  
    const handleNextMonth = () => {
        setDate(date.add(1, 'month'), false);
    }
  
    return (
        <div className="calendar-picker">
            <div className="header">
                <button type="button" onClick={handlePreviousMonth}>&lt;</button>
                    <div className="current-date">{parseToPtBrMonth(date)} / {date.format('YYYY')}</div>
                <button type="button" onClick={handleNextMonth}>&gt;</button>
            </div>
            <div className="days-of-week">
                <div>Dom</div>
                <div>Seg</div>
                <div>Ter</div>
                <div>Qua</div>
                <div>Qui</div>
                <div>Sex</div>
                <div>Sáb</div>
            </div>
            <div className="days">{renderCalendar()}</div>
        </div>
    );
}

export default CalendarPicker;