import './trip-searcher.styles.scss';
import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { TripFiltersContext } from '../../context/trip-filters.context';
import { Button } from '@mui/material';
import dayjs from 'dayjs';
import MapIcon from '../../assets/icons/localização.svg';
import CalendarIcon from '../../assets/icons/calendario.svg';
import CreditCardIcon from '../../assets/icons/cartão.svg';
import SelectField from '../select-field/select-field.component';
import DateField from '../date-field/date-field.component';
import CountField from '../count-field/count-field.component';

const TripSearcher = () => {
    const navigate = useNavigate();
    const { 
        destination, 
        setDestination, 
        boardingDate, 
        setBoardingDate, 
        arrivalDate, 
        setArrivalDate, 
        youngPassengers,
        setYoungPassengers,
        oldPassengers,
        setOldPassengers 
    } = useContext(TripFiltersContext);

    const defaultFormFields = {
        destination: destination,
        boardingDate: boardingDate,
        arrivalDate: arrivalDate,
        youngPassengers: youngPassengers,
        oldPassengers: oldPassengers,
    }
    
    const [formFields, setFormFields] = useState(defaultFormFields);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormFields({ ...formFields, [name]: value});
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setDestination(formFields.destination);
        setBoardingDate(formFields.boardingDate);
        setArrivalDate(formFields.arrivalDate);
        setYoungPassengers(formFields.youngPassengers);
        setOldPassengers(formFields.oldPassengers);
        navigate('/search-results');
    }

    const destinations = [
        'África',
        'América do Norte',
        'América Central',
        'América do Sul',
        'Ásia',
        'Europa',
        'Oceania',
    ]

    return (
        <div className='trip-searcher-container'>
            <form onSubmit={handleSubmit}>
                <div className='fields-container'>
                    <div className='column'>
                        <SelectField 
                            label='Destino' 
                            type='text' 
                            name='destination' 
                            icon={MapIcon}
                            required 
                            onChange={handleChange} 
                            value={destination}
                            options={destinations}/>
                        <CountField
                            label='Passageiros até 64 anos'
                            name='youngPassengers'
                            onChange={handleChange}
                            value={youngPassengers}
                            minValue={formFields.oldPassengers !== 0 ? 0 : 1}/>
                        <CountField
                            label='De 65 até 90 anos'
                            name='oldPassengers'
                            onChange={handleChange}
                            value={oldPassengers}
                            minValue={formFields.youngPassengers !== 0 ? 0 : 1}/>
                    </div>
                    <div className='column'>
                        <DateField 
                            label='Embarque no Brasil' 
                            name='boardingDate' 
                            icon={CalendarIcon}
                            required 
                            onChange={handleChange} 
                            value={boardingDate}/>
                        <div className='payment-advertising-container'>
                            <img className='credit-card-icon' src={CreditCardIcon}/>
                            <span className='advertising'>Parcelamos em até 6x sem juros.</span>
                        </div>
                    </div>
                    <div className='column'>
                        <DateField 
                            label='Desembarque no Brasil' 
                            name='arrivalDate'  
                            icon={CalendarIcon}
                            required 
                            onChange={handleChange} 
                            value={arrivalDate}
                            minDate={formFields.boardingDate}/>
                        <Button type='submit' className='search-button' variant='contained' size='small'>Pesquisar</Button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default TripSearcher;