import './insurance-type-box.styles.scss';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const InsuranceTypeBox = ({insuranceType}) => {
    const { imageUrl, header, title, text, buttonText } = insuranceType;
    const navigate = useNavigate();

    const clickHandler = () => {
        navigate('/trip-ensurance');
    }

    return (
        <div className='insurance-type-box-container'>
            <div 
                className='background-image' 
                style={{backgroundImage: `url(${imageUrl})`}} 
            >
                <div className='insurance-type-box-body'>
                    <div className='column left'>
                        <span className='header'>{header}</span>
                        <h2 className='title'>{title}</h2>
                        <span className='text'>{text}</span>
                    </div>
                    <Button className='action' variant='contained' color='success' size='small' onClick={clickHandler}>{buttonText}</Button>
                </div>
            </div>
        </div>
    )
}

export default InsuranceTypeBox;