import './order-feedback.styles.scss';
import { ReactComponent as CorrectIcon } from '../../assets/icons/correct.svg';

const OrderFeedback = () => {
    return (
        <div className='order-feedback-container'>
            <CorrectIcon className='correct-icon'/>
            <div className='text-container'>
                <h1 className='title'>Pedido efetuado!</h1>
                <h3 className='subtitle'>Em breve enviaremos um e-mail com os dados da sua compra e um link para você efetuar o pagamento.</h3>
            </div>
        </div>
    )
}

export default OrderFeedback;