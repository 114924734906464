import dayjs from "dayjs";

const monthsInPortuguese = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
];

const parseToPtBrMonth = (date) => {
    const month = date.get('month');
    return monthsInPortuguese[month];
}

export { parseToPtBrMonth };