import './review-box.styles.scss';
import { ReactComponent as QuoteIcon } from '../../assets/icons/aspas.svg';
import { ReactComponent as StarIcon } from '../../assets/icons/estrela-preenchida.svg';
import { ReactComponent as HalfStarIcon } from '../../assets/icons/estrela-meio.svg';
import { ReactComponent as EmptyStarIcon } from '../../assets/icons/estrela-vazia.svg';

const ReviewBox = ({review}) => {
    const { userName, commentary, rating } = review;
    let stars = [];

    for (let i = 0; i < 5; i++) {
        let diff = rating - (i * 2);

        if (diff > 1) {
            stars.push(<StarIcon key={i} className='icon'/>);
        } else if (diff === 1) {
            stars.push(<HalfStarIcon key={i} className='icon'/>);
        } else {
            stars.push(<EmptyStarIcon key={i} className='icon'/>);
        }
    }

    return (
        <div className='review-box-container'>
            <div className='content'>
                <QuoteIcon className='icon'/>
                <span className='commentary'>"{commentary}"</span>
            </div>
            <div className='footer'>
                <span className='user-name'>{userName}</span>
                <div className='rating'>
                    {stars.map(star => star)}
                </div>
            </div>
        </div>
    )
}

export default ReviewBox;