import './partners-showcase.styles.scss';
import { getAllPartnersLogos } from '../../service/logo.service';

const PartnersShowcase = () => {
    const partnerList = getAllPartnersLogos();
    return (
        <div className='partners-showcase-container'>
            {partnerList.map(partner => (
                <div key={partner.id} className='logo-container'>
                    {partner.component}
                </div>
            ))}
        </div>
    )
}

export default PartnersShowcase;