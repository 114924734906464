import './faq-grid.styles.scss';
import FaqDropdown from '../faq-dropdown/faq-dropdown.component';

const whatsappLink = "https://api.whatsapp.com/send?phone=5543996823513";

const FaqGrid = () => {
    const leftFaqList = [
        {
            id: 1, 
            question: 'Como faço para escolher o seguro ideal para as minhas viagens?', 
            answer: (<label>Escolher o seguro ideal faz parte de um bom planejamento de viagem. Para garantir que você faça uma boa escolha e tire todas as suas dúvidas, disponibilizamos nosso atendimento pelo WhatsApp para te auxiliar.
                <br/>
                <br/>Entre em contato agora mesmo <b><a href={whatsappLink} target="_blank">clicando aqui</a></b> e fale com nosso atendimento!</label>)
        },
        {
            id: 2, 
            question: 'Quais os tipos de cobertura do seguro viagem?', 
            answer: (<label>As coberturas do seu seguro viagem vão variar de acordo com a seguradora escolhida, entretanto algumas das coberturas comuns são:
               <br/>
               <br/> - Assistência Médica e Hospitalar
               <br/> - Assistência Odontológica
               <br/> - Assistência Farmacêutica 
               <br/> - Regresso Sanitário
               <br/> - Traslado Médico
               <br/> - Seguro bagagem
               <br/> - Despesas Jurídicas</label>)
        },
        {
            id: 3, 
            question: 'Por que escolher meu Seguro Viagem com a Assegurados?', 
            answer: (<label>Nosso principal objetivo é que você possa fazer sua viagem de forma tranquila e, caso precise acionar seu seguro, que você não fique na mão! Por isso escolhemos trabalhar com os melhores seguradoras, de forma a garantir que você sempre esteja bem amparado.
                <br/>
                <br/> Atualmente trabalhamos com as seguradoras listadas abaixo:
                <br/>
                <br/> - Universal Assistance
                <br/> - Assist Card
                <br/> - Affinity Seguro Viagem
                <br/> - Coris Seguro Viagem</label>)
        },
    ]

    const rightFaqList = [
        {
            id: 4, 
            question: 'Como devo proceder caso precise acionar meu seguro viagem?', 
            answer: (<label>Ao fechar o seu seguro, você irá receber uma apólice. Nesse documento constam todos os dados do seguro contratado, bem como os telefones e contatos da seguradora.
                <br/>
                <br/>Todas seguradoras possuem um atendimento 24hr, e algumas delas possuem outros canais de atendimento como Skype, WhatsApp e até aplicativo próprio.</label>)
        },
        {
            id: 5, 
            question: 'O que é o Tratado de Schengen?', 
            answer: (<label>O Tratado de Schengen se trata de um acordo entre países Europeus que permite que turistas brasileiros possam circular pelos países que fazem parte do acordo por um período de 90 dias sem a necessidade de visto.
                <br/>
                <br/>Para isso, os países exigem que os turistas tenham um Seguro Viagem com cobertura mínima de 30 mil euros.</label>)
        },
        {
            id: 6, 
            question: 'Como funciona o processo de cotação e compra do meu seguro viagem com a Assegurados?', 
            answer: (<label>O processo é bem simples, basta entrar em contato com o nosso atendimento <b><a href={whatsappLink} target="_blank">clicando aqui</a></b> e passar alguns dados básicos da sua viagem.
                <br/>
                <br/>Após isso, nosso atendimento vai te apresentar as melhores coberturas e preços! Basta escolher o que te agrada, fazer o pagamento e pronto!
                <br/>      
                <br/>Você receberá a sua apólice por e-mail e por WhatsApp.</label>)
        },
    ]
    return (
        <div className='faq-grid-container'>
            <div className='container'>
                {leftFaqList.map(faq => (
                    <FaqDropdown key={faq.id} faq={faq}/>
                ))}
            </div>
            <div className='container'>
                {rightFaqList.map(faq => (
                    <FaqDropdown key={faq.id} faq={faq}/>
                ))}
            </div>
        </div>
    )
}

export default FaqGrid;