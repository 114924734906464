import './select-field.styles.scss';
import React, { useState, useEffect, useRef } from 'react';
import FormField from '../form-field/form-field.component';

const SelectField = ({ options, value, ...otherProps }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(value);
    const {name, onChange} = otherProps;
    const ref = useRef(this);

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    const handleOutsideClick = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsOpen(false);
        }
    }

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
        onChange({ target: { value: option, name: name } });
    };

    const handleInputClick = () => {
        setIsOpen(!isOpen);
    }

    return (
        <div className="select-field-container" ref={ref}>
            <FormField onClick={handleInputClick} value={selectedOption} autoComplete="off" {...otherProps}/> 
            {isOpen && (
                <div className="options">
                {options.map((option) => (
                    <div
                    key={option}
                    className="option"
                    onClick={() => handleOptionClick(option)}
                    >
                    <label>{option}</label>
                    </div>
                ))}
                </div>
            )}
        </div>
    );
}

export default SelectField;