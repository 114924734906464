import './rounded-box.styles.scss';

const RoundedBox = ({className, children}) => {
    return (
        <div className={`rounded-box-container ${className ? className : ''}`}>
            {children}
        </div>
    )
}

export default RoundedBox;