import dayjs from "dayjs";
import { createContext, useState } from "react";

export const TripFiltersContext = createContext({
    destination: '',
    setDestination: () => {},
    boardingDate: '',
    setBoardingDate: () => {},
    arrivalDate: '',
    setArrivalDate: () => {},
    youngPassengers: 1,
    setYoungPassengers: () => {},
    oldPassengers: 0,
    setOldPassengers: () => {},
});

export const TripFiltersProvider = ({children}) => {
    const [destination, setDestination] = useState('');
    const [boardingDate, setBoardingDate] = useState('');
    const [arrivalDate, setArrivalDate] = useState('');
    const [youngPassengers, setYoungPassengers] = useState(1);
    const [oldPassengers, setOldPassengers] = useState(0);

    const countPassengersByAge = (passengerList) => {
        let youngPassCount = 0, oldPassCount = 0;

        passengerList.forEach(passenger => {
            const { birthDate } = passenger;

            if (birthDate) {
                const parsedBirthDate = dayjs(birthDate, 'DD/MM/YYYY');
                const age = dayjs().diff(parsedBirthDate, 'year');

                if (age >= 65) {
                    oldPassCount++;
                } else {
                    youngPassCount++;
                }
            } else {
                youngPassCount++;
            }
        })

        setYoungPassengers(youngPassCount);
        setOldPassengers(oldPassCount);
    }

    const value = {
        destination,
        setDestination,
        boardingDate,
        setBoardingDate,
        arrivalDate,
        setArrivalDate,
        youngPassengers,
        setYoungPassengers,
        oldPassengers,
        setOldPassengers,
        countPassengersByAge
    };

    return <TripFiltersContext.Provider value={value}>{children}</TripFiltersContext.Provider>;
}