import './filtering-drop-menu.styles.scss';
import { useState, useRef } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { cleanAndConvertToFloat } from '../../utils/string.utils';

const defaultFilters = {
    healthCoverage: '',
    covidCoverage: '',
    insuranceCompanies: ['affinity', 'assist-card', 'universal-assistance']
};

const insuranceCompaniesOptions = [
    {label: 'Affinity', name: 'affinity'},
    {label: 'Assist-Card', name: 'assist-card'},
    {label: 'Universal Assistance', name: 'universal-assistance'}
]

const healthCoverageOptions = [
    {label: 'Todos', value: ''},
    {label: 'Até $30.000,00', value: '30000'},
    {label: 'Até $50.000,00', value: '50000'},
    {label: 'Até $100.000,00', value: '100000'},
    {label: 'Até $250.000,00', value: '250000'},
];

const covidCoverageOptions = [
    {label: 'Todos', value: ''},
    {label: 'Com cobertura', value: 'true'},
    {label: 'Sem cobertura', value: 'false'},
];

const FilteringDropMenu = ({className, onApply, children}) => {
    const [filters, setFilters] = useState(defaultFilters);
    const [anchorEl, setAnchorEl] = useState(null);
    const ref = useRef(this);
    const open = Boolean(anchorEl);

    const { healthCoverage, covidCoverage, insuranceCompanies } = filters;

    const handleClose = (callback) => {
        if (callback) {
            callback();
        }        
        setAnchorEl(null);
    };

    const handleClick = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setAnchorEl(null);
        } else {
            setAnchorEl(event.target);
        }
    }

    const handleRadioChange = (event) => {
        const { name, value } = event.target;

        setFilters({
            ...filters,
            [name]: value
        });
    }

    const handleInsuranceCompanySelection = (event) => {
        const { name } = event.target;

        let newList = [...insuranceCompanies];
        let index = newList.indexOf(name);

        if (index !== -1) {
            newList.splice(index, 1);
        } else {
            newList.push(name);
        }

        setFilters({ 
            ...filters,
            insuranceCompanies: newList
        });
    }

    const handleApplyClick = () => {
        onApply(filters);
        setAnchorEl(null);
    }

    const handleResetClick = () => {
        setFilters(defaultFilters);
        onApply(defaultFilters);
        setAnchorEl(null);
    }

    return (
        <div className={`menu-container ${className}`} ref={ref}>
            <Button
                id="positioned-button"
                className='button dropdown'
                aria-controls={open ? 'positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
            >
                {children}
            </Button>
            <Menu
                id="positioned-menu"
                className='dropdown-menu'
                aria-labelledby="positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={() => handleClose()}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <div className='dropdown'>
                    <div className='row'>
                        <div className='column'>
                            <h3 className='title'>Cobertura médica</h3>
                            <ul className='option-list'>
                                {healthCoverageOptions.map(({label, value}, idx) => (
                                    <li className='option' key={idx}>
                                        <input className='radio' type='radio' name='healthCoverage' checked={healthCoverage === value} value={value} onChange={handleRadioChange}/>
                                        <label className='text'>{label}</label>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className='column'>
                            <h3 className='title'>Cobertura Covid</h3>
                            <ul className='option-list'>
                                {covidCoverageOptions.map(({label, value}, idx) => (
                                    <li className='option' key={idx}>
                                        <input className='radio' type='radio' name='covidCoverage' checked={covidCoverage === value} value={value} onChange={handleRadioChange}/>
                                        <label className='text'>{label}</label>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className='column'>
                            <h3 className='title'>Seguradoras</h3>
                            <ul className='option-list'>
                                {insuranceCompaniesOptions.map(({label, name}, idx) => (
                                    <li className='option' key={idx}>
                                        <input className='checkbox' type='checkbox' name={name} checked={insuranceCompanies.includes(name)} onChange={handleInsuranceCompanySelection}/>
                                        <label className='text'>{label}</label>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className='row buttons-container'>
                        <Button className='button apply-btn' variant="contained" onClick={handleApplyClick}>Aplicar</Button>
                        <Button className='button clear-btn' variant="contained" onClick={handleResetClick}>Limpar</Button>
                    </div>
                </div>
            </Menu>
        </div>
    )
}

export default FilteringDropMenu;