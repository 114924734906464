import './home.styles.scss';
import WorldMapBackground from '../../assets/background-images/mapa.svg';
import HorizontalDivisor from '../../components/horizontal-divisor/horizontal-divisor.component';
import AdvantagesGrid from '../../components/advantages-grid/advantages-grid.component';
import InsuranceTypesGrid from '../../components/insurance-types-grid/insurance-types-grid.component';
import PartnersShowcase from '../../components/partners-showcase/partners-showcase.component';
import TripSearcher from '../../components/trip-searcher/trip-searcher.component';
import BlogPostsShowcase from '../../components/blog-posts-showcase/blog-posts-showcase.component';
import FaqGrid from '../../components/faq-grid/faq-grid.component';
import ReviewsSlider from '../../components/reviews-slider/reviews-slider.component';
import WelcomeMessage from '../../components/welcome-message/welcome-message.component';
import InsurancePurchaseTutorial from '../../components/insurance-purchase-tutorial/insurance-purchase-tutorial.component';

const Home = () => {
    return (
        <div className='home-container' style={{backgroundImage: `url(${WorldMapBackground})`}}>
            <WelcomeMessage/>
            <TripSearcher/>
            <PartnersShowcase/>
            <HorizontalDivisor variant="light">4 passos para contratar o seguro viagem</HorizontalDivisor>
            <InsurancePurchaseTutorial/>
            <HorizontalDivisor variant="light">Principais benefícios do seguro viagem</HorizontalDivisor>
            <AdvantagesGrid/>
            <HorizontalDivisor variant="light">Escolha o seu destino</HorizontalDivisor>
            <InsuranceTypesGrid/>
            <HorizontalDivisor variant="light">Blog</HorizontalDivisor>
            <BlogPostsShowcase/>
            <HorizontalDivisor variant="dark">Dúvidas frequentes</HorizontalDivisor>
            <FaqGrid/>
            <div className='reviews-container'>
                <h1>O que nossos clientes tem a dizer sobre o Seguro Viagem.</h1>
                <ReviewsSlider/>
            </div>
        </div>
    )
}

export default Home;