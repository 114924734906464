import './insurance-offer.styles.scss';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { getPartnerLogo } from '../../service/logo.service';
import { CheckoutContext } from '../../context/checkout.context';
import { TripFiltersContext } from '../../context/trip-filters.context';

const InsuranceOffer = ({insurance}) => {
    const navigate = useNavigate();

    const { setSelectedInsurance } = useContext(CheckoutContext);
    const { youngPassengers, oldPassengers } = useContext(TripFiltersContext);

    const { insuranceCompany, insuranceId, insuranceName, ageInfo, healthCoverage, 
        luggageCoverage, passengerPrice, oldPassengerPrice, coverageUrl, isCovidCovered } = insurance;

    let fullPrice = (passengerPrice * youngPassengers) + (oldPassengerPrice * oldPassengers);
    let discountedPrice = fullPrice * 0.95;

    const handleSelectInsuranceClick = () => {
        setSelectedInsurance(insurance);
        navigate('/checkout');
    }

    return (
        <div className='insurance-offer-container'>
            <div className='partner-container'>
                <span className='title'>Seguradora</span>
                {getPartnerLogo(insuranceCompany).component}
            </div>
            <div className='subscription-container'>
                <span className='title'>Plano, idade e covid</span>
                <span className='subscription'>{insuranceName}</span>
                <span className='age'>{ageInfo}</span>
                <span className='covid'>
                    Cobertura para covid:
                    <br/>
                    <span className='covid-coverage'>
                        {isCovidCovered ? 'Possui' : 'Não possui'}
                    </span>
                </span>
            </div>
            <div className='health-container'>
                <span className='title'>Despesas médicas e seguro bagagem</span>
                <span className='coverage'>
                    Despesas médicas e hospitalares
                    <br/>
                    <span className='value'>
                        {healthCoverage}
                    </span>
                </span>
                <span className='coverage'>
                    Extravio definitivo de bagagem
                    <br/>
                    <span className='value'>
                        {luggageCoverage}
                    </span>
                </span>
                <a className='full-subscription-info' href={coverageUrl} target='_blank'>ver cobertura completa</a>
            </div>
            <div className='price-container'>
                <span className='title'>Valor total</span>
                <span className='price'>R$ {fullPrice.toFixed(2).toLocaleString('pt-BR').replace('\.', ',')}</span>
                <span className='price-description'>em até 6x sem juros</span>
                <span className='price'>R$ {discountedPrice.toFixed(2).toLocaleString('pt-BR').replace('\.', ',')}</span>
                <span className='price-description'>no boleto ou pix</span>
                <div className='buttons-container'>
                    {/* <Button className='compare-btn' variant='contained' size='small'>Comparar plano</Button> */}
                    <Button className='select-btn' variant='contained' size='small' onClick={handleSelectInsuranceClick}>Selecionar seguro</Button>
                </div>
            </div>
        </div>
    )
}

export default InsuranceOffer;