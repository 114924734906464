import { Routes, Route, useLocation } from 'react-router-dom';
import { useEffect } from 'react';

import Home from "./routes/home/home.component";
import Navigation from './routes/navigation/navigation.component';
import MultTrip from './routes/mult-trip/mult-trip.component';
import SearchResults from './routes/search-results/search-results.component';
import TripEnsurance from './routes/trip-ensurance/trip-ensurance.component';
import Authentication from './routes/authentication/authentication.component';
import Registration from './routes/registration/registration.component';
import Orders from './routes/orders/orders.component';
import Checkout from './routes/checkout/checkout.component';
import OrderFeedback from './routes/order-feedback/order-feedback.component';

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Routes>
      <Route path='/' element={<Navigation />}>
        <Route index element={<Home/>}/>
        {/* <Route path='mult-trip' element={<MultTrip/>}/> */}
        <Route path='search-results' element={<SearchResults/>}/>
        <Route path='trip-ensurance' element={<TripEnsurance/>}/>
        {/* <Route path='authentication' element={<Authentication/>}/>
        <Route path='registration' element={<Registration/>}/>
        <Route path='orders' element={<Orders/>}/> */}
        <Route path='checkout' element={<Checkout/>}/>
        <Route path='order-feedback' element={<OrderFeedback/>}/>
      </Route>
    </Routes>
  );
}

export default App;
