import './drop-menu.styles.scss';
import { useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const DropMenu = ({className, options, children}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const ref = useRef(this);
    const open = Boolean(anchorEl);

    useEffect(() => {
        document.addEventListener('click', handleClick);
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, []);

    const handleClose = (callback) => {
        if (callback) {
            callback();
        }        
        setAnchorEl(null);
    };

    const handleClick = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setAnchorEl(null);
        } else {
            setAnchorEl(event.target);
        }
    }

    return (
        <div className={`menu-container ${className}`} ref={ref}>
            <Button
                id="positioned-button"
                className='button dropdown'
                aria-controls={open ? 'positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
            >
                {children}
            </Button>
            <Menu
                id="positioned-menu"
                aria-labelledby="positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={() => handleClose()}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {options?.map((option, idx) => (
                    <MenuItem key={idx} onClick={() => handleClose(option.callback)}>{option.text}</MenuItem>
                ))}
            </Menu>
        </div>
    )
}

export default DropMenu;