import './welcome-message.styles.scss';

const WelcomeMessage = () => {
    return (
        <div className='welcome-message-container'>
            <h1>Viaje com tranquilidade! Compare e encontre o melhor seguro viagem.</h1>
            <span>Não perca tempo. Compare as melhores seguradoras, coberturas e preços, tudo em um só lugar!</span>
        </div>
    )
}

export default WelcomeMessage;