import './payment-method-selector.styles.scss';
import { ReactComponent as PixIcon } from '../../assets/icons/pix.svg';
import { ReactComponent as BarCodeIcon } from '../../assets/icons/bar-code.svg';
import { ReactComponent as CreditCardIcon } from '../../assets/icons/cartão.svg';
import { ReactComponent as BankTransferIcon } from '../../assets/icons/two-way-arrows.svg';

const DiscountBox = () => {
    return (
        <div className='discount-box'>
            <strong className='text'>5% DE DESCONTO</strong>
        </div>
    )
}

const PaymentMethodSelector = ({selected, onSelect}) => {
    const handleSelect = (method) => {
        onSelect(method);
    }

    const checkSelected = (method) => {
        return method === selected ? 'selected' : 'not-selected';
    }

    return (
        <div className='payment-method-selector-container'>
            <div className='method-container'>
                <div className={`method-box ${checkSelected('pix')}`} onClick={() => handleSelect('pix')}>
                    <PixIcon className='pix icon'/>
                </div>
                <DiscountBox/>
            </div>
            <div className='method-container'>
                <div className={`method-box ${checkSelected('bank-slip')}`} onClick={() => handleSelect('bank-slip')}>
                    <BarCodeIcon className='bank-slip icon'/>
                    <span className='title'>Boleto<br/>bancário</span>
                </div>
                <DiscountBox/>
            </div>
            <div className='method-container'>
                <div className={`method-box ${checkSelected('credit-card')}`} onClick={() => handleSelect('credit-card')}>
                    <CreditCardIcon className='credit-card icon'/>
                    <span className='title'>Cartão de<br/>crédito</span>
                </div>
            </div>
            <div className='method-container'>
                <div className={`method-box ${checkSelected('bank-transfer')}`} onClick={() => handleSelect('bank-transfer')}>
                    <BankTransferIcon className='bank-transfer icon'/>
                    <span className='title'>Transferência</span>
                </div>
                <DiscountBox/>
            </div>
        </div>
    )
}

export default PaymentMethodSelector;