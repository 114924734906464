import './date-field.styles.scss';
import React, { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import FormField from '../form-field/form-field.component';
import CalendarPicker from '../calendar-picker/calendar-picker.component';

const DATE_FORMAT = 'DD/MM/YYYY';

const DateField = ({value, minDate = dayjs(), ...otherProps}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(value);
    const {name, onChange} = otherProps;
    const ref = useRef(this);

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    const handleOutsideClick = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsOpen(false);
        }
    }

    const handleDateClick = (day, close) => {
        setSelectedDate(day);

        if (close) {
            setIsOpen(false);
        }
        
        onChange({ target: { value: day, name: name } });
    }

    const handleInputClick = () => {
        setIsOpen(!isOpen);
    }

    return (
        <div className='date-field-container' ref={ref}>
            <FormField onClick={handleInputClick} value={selectedDate ? selectedDate.format(DATE_FORMAT) : ''} autoComplete="off" {...otherProps}/>
            {isOpen && <CalendarPicker handleDateClick={handleDateClick} selectedDate={selectedDate} minDate={minDate}/>}
        </div>
    )
};
  
export default DateField;