import { ReactComponent as AffinityLogo } from '../assets/logos/partners/solid/affinity.svg';
import { ReactComponent as AssistCardLogo } from '../assets/logos/partners/solid/assist-card.svg';
// import { ReactComponent as CorisLogo } from '../assets/logos/partners/coris.svg';
import { ReactComponent as UniversalAssistance } from '../assets/logos/partners/solid/universal-assistance.svg';

const logos = [
    // {id: 1, name: 'coris', component: <CorisLogo className="partner-logo coris"/>, websiteUrl: 'https://www.google.com'},
    {id: 2, name: 'affinity', component: <AffinityLogo className="partner-logo affinity"/>, websiteUrl: 'https://www.affinityseguroviagem.com.br/'},
    {id: 3, name: 'assist-card', component: <AssistCardLogo className="partner-logo assist-card"/>, websiteUrl: 'https://www.assistcard.com/'},
    {id: 4, name: 'universal-assistance', component: <UniversalAssistance className="partner-logo universal-assistance"/>, websiteUrl: 'https://www.universal-assistance.com/'},
]

const getPartnerLogo = (insuranceCompany) => {
    return logos.find(logo => logo.name === insuranceCompany);
}

const getAllPartnersLogos = () => {
    return logos;
}

export { getPartnerLogo, getAllPartnersLogos };