import './horizontal-divisor.styles.scss';

const HorizontalDivisor = ({variant, children}) => {    
    return (
        <div className={`horizontal-divisor-container ${variant}`}>
            <span>{children}</span>
        </div>
    )
}

export default HorizontalDivisor;