import './purchase-details.styles.scss';
import RoundedBox from '../rounded-box/rounded-box.component';
import { Divider } from '@mui/material';

const PurchaseDetails = ({purchase}) => {
    const { youngPassengers, oldPassengers, passengerPrice, oldPassengerPrice, paymentMethod } = purchase;

    const totalYoungPassengersPrice = passengerPrice * youngPassengers;
    const totalOldPassengersPrice = oldPassengerPrice * oldPassengers
    
    const subtotal = totalYoungPassengersPrice + totalOldPassengersPrice;
    const discount = paymentMethod === 'credit-card' ? 0 : subtotal * 0.05;

    const totalPrice = subtotal - discount;

    const formatPrice = (price) => {
        return price.toFixed(2).toLocaleString('pt-BR').replace('\.', ',')
    }

    return (
        <RoundedBox className='purchase-details-container'>
            <h3 className='title'>Resumo da compra</h3>
            <div className='purchase-details'>
                <div className='row'>
                    <span className='quantity'>{youngPassengers} segurado(s) (0 a 65 anos)</span>
                    <span className='value'>R$ {formatPrice(totalYoungPassengersPrice)}</span>
                </div>
                <div className='row'>
                    <span className='quantity'>{oldPassengers} segurado(s) (+65 anos)</span>
                    <span className='value'>R$ {formatPrice(totalOldPassengersPrice)}</span>
                </div>
                <Divider className='horizontal-divider' />
                <div className='row'>
                    <span className='quantity'>Subtotal</span>
                    <span className='value'>R$ {formatPrice(subtotal)}</span>
                </div>
                <div className='row'>
                    <span className='quantity'>Desconto</span>
                    <span className='value'>- R$ {formatPrice(discount)}</span>
                </div>
            </div>
            <div className='row'>
                <h3 className='total'>Valor total</h3><h3 className='total value'>R$ {formatPrice(totalPrice)}</h3>
            </div>
        </RoundedBox>
    )
}

export default PurchaseDetails;