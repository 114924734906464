import './faq-dropdown.styles.scss';
import { ReactComponent as DownArrowIcon } from '../../assets/icons/seta2-baixo.svg';
import { useState, useEffect, useRef } from 'react';

const FaqDropdown = ({faq}) => {
    const [ isExpanded, setIsExpanded ] = useState(false);
    const ref = useRef(this);

    const { question, answer } = faq;
    const expandedClass = isExpanded ? 'expanded' : '';

    useEffect(() => {
        document.addEventListener('click', handleClick);
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, []);

    const expandClickHandler = () => {
        setIsExpanded(!isExpanded);
    }

    const handleClick = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsExpanded(false);
        }
    }

    return (
        <div className={`faq-dropdown-container ${expandedClass}`} ref={ref}>
            <div className={`dropdown ${expandedClass}`}>
                <div className='question-container' onClick={expandClickHandler}>
                    <h3 className='question'>{question}</h3>
                    <DownArrowIcon className='action' onClick={expandClickHandler}/>
                </div>
                <div className='answer-container'>
                    <span className='answer'>{answer}</span>
                </div>
            </div>
        </div>
    )
}

export default FaqDropdown;