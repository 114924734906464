import './basic-trip-searcher.styles.scss';
import { useState, useContext } from 'react';
import { TripFiltersContext } from '../../context/trip-filters.context';
import { Button } from '@mui/material';
import SelectField from '../select-field/select-field.component';
import DateField from '../date-field/date-field.component';
import MapIcon from '../../assets/icons/localização.svg';
import CalendarIcon from '../../assets/icons/calendario.svg';

const countries = [
    'América do Norte',
    'Europa',
    'Ásia',
]

const BasicTripSearcher = () => {
    const { destination, setDestination, boardingDate, setBoardingDate, arrivalDate, setArrivalDate } = useContext(TripFiltersContext);
    const [formFields, setFormFields] = useState({destination, boardingDate, arrivalDate});
    const tripDuration = arrivalDate ? (arrivalDate.diff(boardingDate, 'days') + 1) : 0;

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormFields({ ...formFields, [name]: value});
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setDestination(formFields.destination);
        setBoardingDate(formFields.boardingDate);
        setArrivalDate(formFields.arrivalDate);
    }
    return (
        <div className='basic-trip-searcher-container'>
            <h3 className='title'>Mostrando resultados para:</h3>
            <form className='form-container' onSubmit={handleSubmit}>
                <SelectField
                    label='Destino' 
                    type='text' 
                    name='destination' 
                    variant='light blue'
                    icon={MapIcon}
                    required 
                    onChange={handleChange} 
                    value={destination}
                    options={countries}/>
                <DateField 
                    label='Partida' 
                    type='text' 
                    name='boardingDate' 
                    variant='light blue'
                    icon={CalendarIcon}
                    required 
                    onChange={handleChange} 
                    value={boardingDate}/>
                <DateField 
                    label='Retorno' 
                    type='text' 
                    name='arrivalDate'  
                    variant='light blue'
                    icon={CalendarIcon}
                    required 
                    onChange={handleChange} 
                    value={arrivalDate}
                    minDate={boardingDate}/>
                <Button type='submit' className='action' variant='contained' size='small'>Pesquisar</Button>
            </form>
            {tripDuration > 0 && <span className='trip-duration'>Permanência de <b>{tripDuration} {tripDuration === 1 ? 'dia' : 'dias'}</b></span>}
        </div>
    )
}

export default BasicTripSearcher;