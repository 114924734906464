import './reviews-slider.styles.scss';
import ReviewBox from '../review-box/review-box.component';
import { ReactComponent as ArrowIcon } from '../../assets/icons/seta-direita.svg';
import { useState } from 'react';

const reviewList = [
    {
        id: 1, 
        userName: 'Jennifer L.', 
        commentary: 'O pessoal da Assegurados me atendeu super bem. ' 
            + 'Eu não sabia qual seguro exatamente escolher e eles me deram '  
            + 'todo suporte necessário para que eu pudesse viajar tranquila. Recomendo.', 
        rating: 9,
    },
    {
        id: 2, 
        userName: 'João C.', 
        commentary: 'Nunca tinha usado um site de cotação de seguro viagem antes, mas a Assegurados me surpreendeu. ' 
            + 'Foi fácil, rápido e encontrei um seguro que atendia todas as minhas necessidades.', 
        rating: 10,
    },
    {
        id: 3, 
        userName: 'Rogério S.', 
        commentary: 'Com poucos cliques encontrei o seguro viagem que atendesse às exigências do país que viajei. Fácil e rápido e usar. Indiquei para outros.', 
        rating: 10,
    },
    {
        id: 4, 
        userName: 'Gabriela M.', 
        commentary: 'Precisava de um seguro viagem com cobertura para doenças preexistentes e achei que seria impossível encontrar um que me atendesse. ' 
            + 'Mas a Assegurados me surpreendeu e me ajudou a encontrar a opção perfeita. Recomendo muito!', 
        rating: 9,
    },
];

const ReviewsSlider = () => {
    const [reviewsIdxToShow, setReviewsIdxToShow] = useState([0,1]);

    const nextReviewClickHandler = () => {
        let newReviewsToShow = [];
        reviewsIdxToShow.map(id => {
            if (id + 1 >= reviewList.length) {
                newReviewsToShow.push(0);
            } else {
                newReviewsToShow.push(id +1);
            }
        });
        setReviewsIdxToShow(newReviewsToShow);
    }

    const previousReviewClickHandler = () => {
        let newReviewsToShow = [];
        reviewsIdxToShow.map(id => {
            if (id - 1 < 0) {
                newReviewsToShow.push(reviewList.length -1);
            } else {
                newReviewsToShow.push(id -1);
            }
        });
        setReviewsIdxToShow(newReviewsToShow);
    }

    let displayedReviews = [];

    reviewsIdxToShow.forEach(idx => displayedReviews.push(reviewList[idx]));

    return (
        <div className='reviews-slider-container'>
            <div className='button reverse' onClick={previousReviewClickHandler}>
                <ArrowIcon className='icon'/>
            </div>
            {displayedReviews.map(review => (
                <ReviewBox key={review.id} review={review}/>
            ))}
            <div className='button' onClick={nextReviewClickHandler}>
                <ArrowIcon className='icon'/>
            </div>
        </div>
    )
}

export default ReviewsSlider;