import './blog-posts-showcase.styles.scss';
import BlogPostPreview from '../blog-post-preview/blog-post-preview.component';

const BlogPostsShowcase = () => {
    const posts = [
        {
            id: 1, 
            imageUrl: 'https://assegurados.com/wp-content/uploads/2023/02/Seguro-viagem-_1_.webp', 
            title: 'Seguro viagem: descubra por que é essencial para a sua viagem', 
            preview: 'Se você está se programando para fazer uma viagem internacional, você precisa...',
            postUrl: 'https://assegurados.com/seguro-viagem-descubra-por-que-e-essencial-para-sua-viagem/',
        },
        {
            id: 2, 
            imageUrl: 'https://assegurados.com/wp-content/uploads/2023/04/checklist-viagem-internacional-assegurados.webp', 
            title:'Vai viajar para fora? Confira nosso checklist', 
            preview: 'Viajar para o exterior pode ser emocionante, mas também pode ser estressante se...',
            postUrl: 'https://assegurados.com/vai-viajar-para-fora-confira-nosso-checklist/',
        },
        {
            id: 3, 
            imageUrl: 'https://assegurados.com/wp-content/uploads/2023/03/capas-blog-3-1.webp', 
            title: 'Orlando: o melhor guia pelos parques temáticos', 
            preview: 'Orlando é uma cidade conhecida por seus parques temáticos e atrações turísticas. A cidade é...',
            postUrl: 'https://assegurados.com/orlando-o-melhor-guia-pelos-parques-tematicos/',
        },
    ]
    return (
        <div className='blog-posts-showcase-container'>
            {posts.map(post => (
                <BlogPostPreview key={post.id} post={post}/>
            ))}
        </div>
    )
}

export default BlogPostsShowcase;