import './count-field.styles.scss';
import { useState } from 'react';

const CountField = ({label, name, onChange, value = 0, minValue = 0}) => {
    const [count, setCount] = useState(value);

    const fireOnChange = (newCount) => {
        onChange({ target: { value: newCount, name: name } });
    }

    const handleSubtractClick = () => {
        if (count > minValue) {
            const newCount = count - 1;
            setCount(newCount);
            fireOnChange(newCount);
        }
    }

    const handleSumClick = () => {
        const newCount = count + 1;
        setCount(newCount);
        fireOnChange(newCount);
    }

    return (
        <div className='count-field-container'>
            <label className='label'>{label}</label>
            <div className='value-container'>
                <div className='round-container' onClick={handleSubtractClick}>
                    -
                </div>
                <label className='value'>{count}</label>
                <div className='round-container' onClick={handleSumClick}>
                    +
                </div>
            </div>
        </div>
    )
}

export default CountField;