import './insurance-types-grid.styles.scss';
import InsuranceTypeBox from '../insurance-type-box/insurance-type-box.component';
import UnitedStatesImage from '../../assets/background-images/estados_unidos.png';
import CruiseImage from '../../assets/background-images/cruzeiro.png';
import BrazilImage from '../../assets/background-images/brasil.png';
import ParisImage from '../../assets/background-images/paris.png';

const InsuranceTypesGrid = () => {
    const insuranceTypes = [
        {
            id: 1,
            imageUrl: UnitedStatesImage,
            header: 'SEGURO VIAGEM PARA',
            title: 'ESTADOS UNIDOS',
            text: 'Explore todos os cantos dos Estados Unidos sem se preocupar com nada e economize na sua viagem com total segurança.',
            buttonText: 'Clique e saiba mais',
            bgColor: 'red',
        },
        {
            id: 2,
            imageUrl: CruiseImage,
            header: 'SEGURO VIAGEM PARA',
            title: 'CRUZEIRO',
            text: 'Tenha uma viagem inesquecível a bordo de um cruzeiro, sem preocupações e com economia garantida.',
            buttonText: 'Clique e saiba mais',
            bgColor: 'blue',
        },
        {
            id: 3,
            imageUrl: BrazilImage,
            header: 'SEGURO VIAGEM PARA',
            title: 'AMÉRICA DO SUL',
            text: 'Descubra as maravilhas da América do Sul sem nenhuma preocupação e com o melhor preço.',
            buttonText: 'Clique e saiba mais',
            bgColor: 'green',
        },
        {
            id: 4,
            imageUrl: ParisImage,
            header: 'SEGURO VIAGEM PARA',
            title: 'EUROPA',
            text: 'Encontre o seguro que atenda todas exigências dos países Europeus e que tenha o melhor preço.',
            buttonText: 'Clique e saiba mais',
            bgColor: 'yellow',
        },
    ]

    return (
        <div className='insurance-types-grid-container'>
            {insuranceTypes.map(insuranceType => <InsuranceTypeBox key={insuranceType.id} insuranceType={insuranceType}/>)}
        </div>
    )
}

export default InsuranceTypesGrid;