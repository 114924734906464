import './filtered-result-list.styles.scss';
import { useState, useEffect } from 'react';
import InsuranceOffer from '../insurance-offer/insurance-offer.component';
import Loader from '../loader/loader.component';
import DropMenu from '../drop-menu/drop-menu.component';
import FilteringDropMenu from '../filtering-drop-menu/filtering-drop-menu.component';
import { cleanAndConvertToFloat } from '../../utils/string.utils';

const FilteredResultList = ({resultList, loading}) => {
    let [ orderedResultList, setOrderedResultList ] = useState([]);
    const [ filters, setFilters ] = useState(null);
    
    useEffect(() => {
        setOrderedResultList(resultList);
    }, [resultList]);

    const reorderResultList = (orderBy, orderDirection) => {
        orderedResultList.sort((a, b) => {
            let leftAtt = cleanAndConvertToFloat(a[orderBy]), rightAtt = cleanAndConvertToFloat(b[orderBy]);

            if (orderDirection.toUpperCase() === 'DESC') {
                if (leftAtt > rightAtt) {
                    return -1;
                } else if (leftAtt < rightAtt) {
                    return 1;
                } else {
                    return 0;
                }
            } else {
                if (leftAtt < rightAtt) {
                    return -1;
                } else if (leftAtt > rightAtt) {
                    return 1;
                } else {
                    return 0;
                }
            }
        })

        setOrderedResultList([...orderedResultList]);
    }

    const orderingMenuOptions = [
        { text: 'Preço Crescente', callback: () => reorderResultList('passengerPrice', 'ASC')},
        { text: 'Preço Decrescente', callback: () => reorderResultList('passengerPrice', 'DESC')},
        { text: 'Coberturas Médicas Crescente', callback: () => reorderResultList('healthCoverage', 'ASC')},
        { text: 'Coberturas Médicas Decrescente', callback: () => reorderResultList('healthCoverage', 'DESC')},
        { text: 'Coberturas de Bagagem Crescente', callback: () => reorderResultList('luggageCoverage', 'ASC')},
        { text: 'Coberturas de Bagagem Decrescente', callback: () => reorderResultList('luggageCoverage', 'DESC')},
    ];

    const handleApplyFilters = (newFilters) => {
        if (newFilters) {
            setFilters(newFilters);
        }
    }

    const filterResultList = () => {
        let filteredList = [];

        if (orderedResultList) {
            filteredList = [...orderedResultList];

            if (filters) {
                const { healthCoverage, covidCoverage, insuranceCompanies } = filters;

                if (healthCoverage) {
                    let parsedHealthCoverage = parseFloat(healthCoverage);
                    filteredList = filteredList.filter(item => cleanAndConvertToFloat(item.healthCoverage) < parsedHealthCoverage);
                }
                if (covidCoverage === 'true' || covidCoverage === 'false') {
                    let parsedCovidCoverage = covidCoverage === 'true';
                    filteredList = filteredList.filter(item => item.isCovidCovered === parsedCovidCoverage);
                }
                if (insuranceCompanies) {
                    filteredList = filteredList.filter(item => insuranceCompanies.includes(item.insuranceCompany));
                }
            }
        }

        return filteredList;
    }

    return (
        <div className='filtered-result-list-container'>
            <div className='result-filter-container'>
                {!loading && <h3 className='result-count'>Encontramos {filterResultList().length} seguro(s) selecionado(s) para você.</h3>}
                <div className='dropdowns-container'>
                    <FilteringDropMenu className='filtering-menu' onApply={handleApplyFilters}>Filtrar por</FilteringDropMenu>
                    <DropMenu className='ordering-menu' options={orderingMenuOptions}>Ordenar por</DropMenu>
                </div>
            </div>
            <div className='result-list-container'>
                {
                    loading ? <Loader/> 
                        : filterResultList().map(result => (
                            <InsuranceOffer key={result.insuranceId} insurance={result}/>
                        ))
                }
            </div>
        </div>
    )
}

export default FilteredResultList;