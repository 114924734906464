import { Outlet, Link } from 'react-router-dom';
import { Fragment, useContext } from 'react';
import { ReactComponent as Logo } from '../../assets/logos/solid/white/horizontal1.svg';
import { UserContext } from '../../context/user.context';
import Footer from '../../components/footer/footer.component';
import UserInformation from '../../components/user-information/user-information.component';

import './navigation.styles.scss';

const Navigation = () => {
    const { currentUser } = useContext(UserContext);
    return (
        <Fragment>
            <div className='background'>
                <div className='header'>
                    <a href='https://api.whatsapp.com/send?phone=5543996823513' target='_blank'>CONVERSE CONOSCO NO WHATSAPP &#8594; (43) 99682-3513</a>
                </div>
                <div className='navigation'>
                    <Link className='logo-container' to='/'>
                        <Logo className='logo'/>
                    </Link>
                    <div className='nav-links-container'>
                        <Link className='nav-link' to='/trip-ensurance'>
                            Seguro viagem
                        </Link>
                        {/* <Link className='nav-link' to='/mult-trip'>
                            Multi-Trip
                        </Link> */}
                        <Link className='nav-link' to='https://assegurados.com/sobre-nos/' target='_blank'>
                            Sobre nós
                        </Link>
                        <Link className='nav-link' to='https://assegurados.com/blogassegurados/' target='_blank'>
                            Blog
                        </Link>
                        {/* {
                            currentUser ? (
                                <UserInformation className='nav-link'/>
                            ) : (
                                <Link className='nav-link' to='/authentication'>
                                    Login
                                </Link>
                            )
                        } */}
                    </div>
                </div>
            </div>
            <Outlet/>
            <Footer/>
        </Fragment>
    );
}

export default Navigation;