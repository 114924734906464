import './search-results.styles.scss';
import { useState, useEffect, useContext } from 'react';
import { TripFiltersContext } from '../../context/trip-filters.context';
import { getQuotationsByFilter } from '../../service/quotation.service';
import BasicTripSearcher from '../../components/basic-trip-searcher/basic-trip-searcher.component';
import FilteredResultList from '../../components/filtered-result-list/filtered-result-list.component';

const SearchResults = () => {
    const [ resultList, setResultList ] = useState(null);
    const [ loading, setLoading ] = useState(resultList == null)
    const { destination, boardingDate, arrivalDate, youngPassengers, oldPassengers } = useContext(TripFiltersContext);

    useEffect(() => {
        if (destination && boardingDate && arrivalDate && (youngPassengers || oldPassengers)) {
            setLoading(true);
            const filter = {
                destination, 
                boardingDate, 
                arrivalDate, 
                youngPassengers, 
                oldPassengers
            };
    
            const fetchQuotations = async () => {
                const data = await getQuotationsByFilter(filter);
                setResultList(data);
                setLoading(false);
            };
    
            fetchQuotations();
        } else {
            setResultList([]);
            setLoading(false);
        }
    }, [destination, boardingDate, arrivalDate, youngPassengers, oldPassengers]);

    return (
        <div className='search-results-container'>
            <BasicTripSearcher/>
            <FilteredResultList resultList={resultList} loading={loading}/>
        </div>
    )
}

export default SearchResults;