import './insurance-purchase-tutorial.styles.scss';
import InsurancePurchaseStep from '../insurance-purchase-step/insurance-purchase-step.component';
import MagnifierImg from '../../assets/icons/magnifying-glass.svg';
import ComparisonImg from '../../assets/icons/comparison.png';
import DoubleUsersImg from '../../assets/icons/double-users.svg';
import ChecklistImg from '../../assets/icons/checklist.svg';

const steps = [
    { 
        title: '1. COTAÇÃO', 
        text: 'Insira as informações da sua viagem', 
        logoUrl: MagnifierImg, 
        className: 'red-title'
    },
    { 
        title: '2. COMPARAÇÂO', 
        text: 'Compare coberturas, preços e seguradoras e escolha o seguro ideal para você', 
        logoUrl: ComparisonImg, 
        className: 'yellow-title'
    },
    { 
        title: '3. INFORMAÇÕES DOS VIAJANTES', 
        text: 'Insira as informações completas dos viajantes', 
        logoUrl: DoubleUsersImg, 
        className: 'green-title'
    },
    { 
        title: '4. PAGAMENTO E RECEBIMENTO', 
        text: 'Efetue o pagamento e receba a sua apólice via e-mail e WhatsApp', 
        logoUrl: ChecklistImg, 
        className: 'blue-title'
    },
]

const InsurancePurchaseTutorial = () => {
    return (
        <div className='insurance-purchase-tutorial-container'>
            {steps.map((step, idx) => (
                <InsurancePurchaseStep key={idx} {...step} />
            ))}
        </div>
    )
}

export default InsurancePurchaseTutorial;