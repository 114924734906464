import './form-field.styles.scss';
import InputMask from 'react-input-mask';

const FormField = ({label, icon, variant, errorMessage, ...otherProps}) => {
    return (
        <div className={`form-field-container ${variant ? variant : ''}`}>
            <InputMask 
                className={`input-field ${icon ? '' : 'no-icon'} ${!!errorMessage ? 'error' : ''}`} 
                maskChar=" " 
                value={otherProps.value} 
                onChange={otherProps.onChange} 
                {...otherProps}/>
            {icon && <img className='icon' src={icon}/>}
            <label className={`${otherProps.value?.length ? 'shrink' : ''} ${icon ? '' : 'no-icon'} input-label`}>
                {label}
            </label>
            {!!errorMessage && <span className='error-message'>{errorMessage}</span>}
        </div>
    )
}

export default FormField;