import axios from 'axios';

const BASE_URL = 'http://127.0.0.1:3030/orders';

const placeNewOrder = async (order) => {
    try {
        return axios.post(`/api/orders/new`, order);
    } catch (error) {
        console.error(`Error placing new order: ${error}`);
    }
}

export { placeNewOrder };