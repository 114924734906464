import './advantages-grid.styles.scss';
import AdvantageBox from '../advantage-box/advantage-box.component';

import MessageIcon from '../../assets/icons/balao-conversa.svg'
import MedicIcon from '../../assets/icons/medico.svg'
import DrugStoreIcon from '../../assets/icons/farmacia.svg'
import LuggageIcon from '../../assets/icons/bagagem.svg'
import ClockIcon from '../../assets/icons/relogio.svg'
import HeadsetIcon from '../../assets/icons/atendimento1.svg'

const AdvantagesGrid = () => {
    const advantages = [
        {
            id: 1, 
            imageUrl: MessageIcon, 
            title: 'Atendimento em português 24 horas por dia, todos os dias', 
            text: 'A qualquer momento que precisar, você terá suporte 24h, 7 dias por semana, em português, durante toda a sua viagem.'
        },
        {
            id: 2, 
            imageUrl: MedicIcon, 
            title: 'Despesas médicas e hospitalares', 
            text: 'Em casos de acidente ou doenças contraídas durante a viagem, garanta atendimento médico, hospitalar ou odontológico.'
        },
        {
            id: 3, 
            imageUrl: DrugStoreIcon, 
            title: 'Reembolso de despesas farmacêuticas', 
            text: 'Em casos de medicamentos prescritos para o tratamento durante a viagem, receba o reembolso dos gastos.'
        },
        {
            id: 4, 
            imageUrl: LuggageIcon, 
            title: 'Atraso e Extravio de Bagagem', 
            text: 'Em casos de atraso ou perda de bagagem, receba o reembolso das depesas.'
        },
        {
            id: 5, 
            imageUrl: ClockIcon, 
            title: 'Cancelamento e atraso de voos', 
            text: 'Em casos de perda ou atraso de voos ou conexões, receba o reembolso das despesas.'
        },
        {
            id: 6, 
            imageUrl: HeadsetIcon, 
            title: 'Rede credenciada', 
            text: 'Contamos com uma ampla rede credenciada para auxiliar o segurado, 24h, em qualquer lugar.'
        },
    ]
    return (
        <div className='advantages-grid-container'>
            <div className='row'>
                <AdvantageBox advantage={advantages[0]}/>
                <div className='column-divider'/>
                <AdvantageBox advantage={advantages[1]}/>
                <div className='column-divider'/>
                <AdvantageBox advantage={advantages[2]}/>
            </div>
            <div className='row-divider'/>
            <div className='row'>
                <AdvantageBox advantage={advantages[3]}/>
                <div className='column-divider reverse'/>
                <AdvantageBox advantage={advantages[4]}/>
                <div className='column-divider reverse'/>
                <AdvantageBox advantage={advantages[5]}/>
            </div>
        </div>
    )
}

export default AdvantagesGrid;