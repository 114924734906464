import './basic-insurance-offer.styles.scss';
import dayjs from 'dayjs';
import { getPartnerLogo } from '../../service/logo.service';
import RoundedBox from '../rounded-box/rounded-box.component';

const DATE_FORMAT = 'DD/MM/YYYY';

const formatDate = (date) => dayjs(date).format(DATE_FORMAT);

const BasicInsuranceOffer = ({insurance, boardingDate, arrivalDate, destination}) => {
    const { insuranceCompany, insuranceName, coverageUrl, insuranceUrl, ageInfo } = insurance;
    return (
        <RoundedBox className='basic-insurance-offer-container'>
            <div className='column info-container'>
                <div className='column'>
                    <h3 className='destination title'>{destination.toUpperCase()}</h3>
                    <span className='trip-duration'>{formatDate(boardingDate)} - {formatDate(arrivalDate)}</span>
                </div>
                <h3 className='insurance-name title'>{insuranceName}</h3>
                <span className='age'>{ageInfo}</span>
                <a className='full-subscription-info link' href={coverageUrl} target='_blank'>ver cobertura completa</a>
                <a className='full-subscription-info link' href={insuranceUrl} target='_blank'>informações e regras do plano</a>
            </div>
            <div className='column logo-container'>
                {getPartnerLogo(insuranceCompany).component}
            </div>
        </RoundedBox>
    )
}

export default BasicInsuranceOffer;