import axios from 'axios';

const BASE_URL = 'http://127.0.0.1:3030/quotations';

const getQuotationsByFilter = async (filter) => {
    try {
        const result = await axios(`/api/quotations`, {
            params: {
                filter: filter
            }
        });
        return result.data;
    } catch (error) {
        console.error(`Error fetching quotations: ${error}`);
        return [];
    }
}

export {getQuotationsByFilter};